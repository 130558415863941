import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import CatArticlesGrid from "../components/catarticles-grid";
import Seo from "../components/Seo";

const CatPage = ({ data }) => {
  const catarticle = data.strapiCategory.articles;
  const cat = data.strapiCategory;

  const seo = {
    metaTitle: cat.seo_title || cat.name,
    metaDescription: cat.seo_description || cat.description,
  };
  return (
    <Layout>
      <Seo seo={seo} />
      <header>
        <StaticImage
          src="../assets/images/blog/banner-blog.png"
          alt={cat.name}
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      </header>
      <main className="mt-8">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-neutral-700">{cat.name}</h1>
          <h2 className="mt-4 text-2xl text-neutral-500">{cat.description}</h2>
        </div>
        <div className="mt-6 mb-20 flex flex-wrap gap-5">
          <CatArticlesGrid articles={catarticle} />
        </div>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiCategory(slug: { eq: $slug }) {
      id
      slug
      name
      description
      seo_desc
      seo_title
      articles {
        id
        slug
        title
        description
        cover {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.77)
            }
          }
        }
      }
    }
  }
`;
export default CatPage;
